.repay-debts-container {
  overflow-y: auto;
  box-sizing: border-box;
}

.repay-debts-loan-table-container {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.repay-debts-loan-details-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.repay-debts-loan-details-table th {
  background-color: #e8f4ff;
  padding: 12px 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: normal;
  min-height: 50px;
  line-height: 1.2;
  vertical-align: top;
  color: #000000;
}

.repay-debts-loan-details-table td {
  padding: 8px 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Column widths */
.repay-debts-loan-details-table th:nth-child(1),
.repay-debts-loan-details-table td:nth-child(1) {
  width: 20%;
}

.repay-debts-loan-details-table th:nth-child(2),
.repay-debts-loan-details-table td:nth-child(2) {
  width: 15%;
}

.repay-debts-loan-details-table th:nth-child(3),
.repay-debts-loan-details-table td:nth-child(3) {
  width: 15%;
}

.repay-debts-loan-details-table th:nth-child(4),
.repay-debts-loan-details-table td:nth-child(4) {
  width: 15%;
}

.repay-debts-loan-details-table th:nth-child(5),
.repay-debts-loan-details-table td:nth-child(5) {
  width: 15%;
}

.repay-debts-loan-details-table th:nth-child(6),
.repay-debts-loan-details-table td:nth-child(6) {
  width: 20%;
}

.repay-debts-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
}

.repay-debts-add-button,
.repay-debts-analyze-button,
.repay-debts-take-action-btn,
.repay-debts-generate-strategy-btn,
.repay-debts-save-button,
.repay-debts-delete-button {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  min-width: 180px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #007bff;
  color: white;
}

.repay-debts-add-button:hover,
.repay-debts-analyze-button:hover,
.repay-debts-take-action-btn:hover,
.repay-debts-generate-strategy-btn:hover,
.repay-debts-save-button:hover:not(:disabled),
.repay-debts-delete-button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.repay-debts-save-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.repay-debts-strategy-display {
  padding: 20px;
  margin-top: 20px;
}

.repay-debts-strategy-display h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
}

.repay-debts-strategy-explanation {
  margin: 20px 0;
  padding: 25px;
  background-color: #f8f9fa;
  border-radius: 8px;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.repay-debts-strategy-explanation h3 {
  color: #1565c0;
  margin-bottom: 20px;
  font-size: 1.5em;
  font-weight: 600;
  font-family: inherit;
}

.repay-debts-strategy-text {
  margin-bottom: 25px;
}

.repay-debts-strategy-text p {
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: 1rem;
  color: #2c3e50;
  font-family: inherit;
}

.repay-debts-strategy-target {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
  margin-top: 20px;
}

.repay-debts-strategy-target p {
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: 1rem;
  color: #2c3e50;
  font-family: inherit;
}

.repay-debts-strategy-highlight {
  font-weight: 600;
  color: #1565c0;
}

.repay-debts-strategy-emphasis {
  font-style: italic;
  color: #2c3e50;
}

.repay-debts-loan-order-list {
  margin-top: 15px;
  padding-left: 25px;
  list-style-type: decimal;
}

.repay-debts-loan-order-list li {
  margin-bottom: 12px;
  line-height: 1.6;
  font-size: 1rem;
  color: #2c3e50;
  font-family: inherit;
}

.repay-debts-loan-order-list li strong {
  color: #1565c0;
}

.repay-debts-loan-details-heading {
  margin-bottom: 5px;
  font-size: 24px;
  color: #333;
}

.repay-debts-loan-details-subtitle {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
  font-weight: normal;
}

.repay-debts-description {
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 32px;
  border: 1px solid #007bff;
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.1);
}

.repay-debts-description-header {
  text-align: center;
  margin-bottom: 20px;
}

.repay-debts-description-header h2 {
  color: #1565c0;
  font-size: 1.8rem;
  margin-bottom: 8px;
}

.repay-debts-description-subtitle {
  color: #1976d2;
  font-size: 1.1rem;
  margin: 0;
}

.repay-debts-description-features {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 12px;
  margin-top: 20px;
  padding: 0 12px;
}

.repay-debts-feature-item {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: white;
  padding: 10px 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.1);
  transition: transform 0.2s ease;
}

.repay-debts-feature-item:hover {
  transform: translateY(-2px);
}

.repay-debts-analysis-summary,
.repay-debts-repayment-strategy,
.repay-debts-extra-payment-box {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.repay-debts-loan-summary-table,
.repay-debts-repayment-table,
.repay-debts-strategy-options-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  background-color: white;
}

.repay-debts-loan-summary-table th,
.repay-debts-repayment-table th,
.repay-debts-strategy-options-table th {
  background-color: #e8f4ff;
  color: #000000;
  font-weight: 600;
  padding: 12px 8px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 14px;
  white-space: normal;
  vertical-align: middle;
  line-height: 1.3;
  height: auto;
}

.repay-debts-loan-summary-table td,
.repay-debts-repayment-table td,
.repay-debts-strategy-options-table td {
  padding: 12px 8px;
  text-align: center;
  border: 1px solid #ddd;
  font-size: 14px;
  white-space: normal;
  vertical-align: middle;
}

.repay-debts-strategy-options-table tr:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

.repay-debts-strategy-options-table tr.selected {
  background-color: #e9ecef;
}

/* Media queries */
@media (max-width: 768px) {
  .repay-debts-description-features {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .repay-debts-feature-item {
    flex: 1 1 calc(50% - 12px);
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .repay-debts-feature-item {
    flex: 1 1 100%;
  }
}

/* Specific adjustments for delete button */
.repay-debts-delete-button {
  min-width: auto;
  padding: 8px 16px;
  font-size: 14px;
}

.repay-debts-detailed-calculations {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
}

.calculation-step {
    margin: 1.5rem 0;
    padding: 1rem;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.calculation-step ul {
    list-style-type: none;
    padding-left: 0;
}

.calculation-step li {
    margin: 0.5rem 0;
    color: #444;
}

.final-impact {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #e9ecef;
    border-radius: 6px;
}

.loan-calculation-details {
    margin-bottom: 2rem;
}

.repay-debts-cascade-summary {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #e9ecef;
    border-radius: 8px;
}

.final-impact-table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.final-impact-table th,
.final-impact-table td {
    padding: 0.75rem;
    text-align: right;
    border: 1px solid #dee2e6;
}

.final-impact-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    text-align: center;
}

.final-impact-table tr:nth-child(even) {
    background-color: #f8f9fa;
}

.final-impact-table td:first-child {
    text-align: left;
    font-weight: 500;
}

.final-impact-table tfoot {
    font-weight: bold;
    background-color: #e9ecef;
}

.final-impact-table tfoot td {
    text-align: right;
}

.repay-debts-loading {
    text-align: center;
    padding: 20px;
    font-size: 1.1em;
    color: #666;
}

.calculation-block {
    margin: 20px 0;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.calculation-block h4 {
    color: #2c3e50;
    margin-bottom: 15px;
    padding-bottom: 8px;
    border-bottom: 2px solid #e9ecef;
}

.table-container {
    overflow-x: auto;
    margin: 15px 0;
}

.table-container table {
    width: 100%;
    border-collapse: collapse;
}

.table-container th,
.table-container td {
    padding: 12px;
    border: 1px solid #dee2e6;
    text-align: left;
}

.table-container th {
    background-color: #e9ecef;
    font-weight: 600;
}

.table-container tr:nth-child(even) {
    background-color: #f8f9fa;
}

.strategy-explanation {
    margin: 15px 0;
    color: #495057;
    font-size: 1.1em;
}

.calculation-block strong {
    color: #2c3e50;
}

.table-container td {
    padding: 12px 20px;
}

.priority-list {
    margin-top: 10px;
    margin-left: 20px;
    list-style-type: decimal;
}

.priority-list li {
    margin: 5px 0;
}

.next-loan {
    font-weight: bold;
}

.next-indicator {
    color: #4CAF50;
    font-style: italic;
    margin-left: 5px;
}

.strategy-text, .strategy-target {
    margin: 10px 0;
    font-size: 16px;

}

.repay-debts-save-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.save-message {
    color: #2e7d32;
    font-size: 14px;
    margin-top: 8px;
    text-align: center;
}

.save-message.error {
    color: #d32f2f;
}