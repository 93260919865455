.dashboard-container {
    padding: 20px;
    max-width: 1200px;
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

/* Full width section */
.dashboard-section.full-width {
    margin: 0 auto;
}

/* Quick expense entry styling */
.dashboard-expense-form-row {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    margin: 15px 0;
    width: 100%;
    box-sizing: border-box;
}

.dashboard-expense-form-row input,
.dashboard-expense-form-row select,
.dashboard-expense-form-row button {
    height: 38px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

/* Update grid layout */
.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .dashboard-expense-form-row {
        grid-template-columns: 1fr;
    }
    
    .dashboard-grid {
        grid-template-columns: 1fr;
    }
}

/* Quick Expense Entry Styles */
.dashboard-quick-expense-entry {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 10px;
    margin-bottom: 20px;
}

.dashboard-quick-expense-entry input,
.dashboard-quick-expense-entry select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

/* Expenses Overview Styles */
.dashboard-expenses-overview {
    display: flex;
    gap: 30px;
    padding: 20px 0;
    border-top: 1px solid #eee;
}

.dashboard-expenses-overview-left {
    flex: 0.4;
}

.dashboard-expenses-overview-right {
    flex: 0.6;
    padding-left: 30px;
    border-left: 1px solid #eee;
}

.dashboard-expenses-overview h4 {
    color: #333;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: left;
}

.dashboard-current-expenses p,
.dashboard-expenses-insights p {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
}

/* Action Button Styles */
.dashboard-action-button {
    background: #1976D2;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
}

.dashboard-action-button:hover {
    background: #1565C0;
}

.dashboard-action-button.secondary {
    background: #f5f5f5;
    color: #1976D2;
    border: 1px solid #1976D2;
}

.dashboard-action-button.secondary:hover {
    background: #e3f2fd;
}

.dashboard-details-button {
    margin-top: 20px;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .dashboard-expense-form-row {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 768px) {
    .dashboard-expense-form-row {
        grid-template-columns: 1fr;
    }
    
    .dashboard-expenses-overview {
        flex-direction: column;
    }
    
    .dashboard-expenses-overview-right {
        padding-left: 0;
        border-left: none;
        border-top: 1px solid #eee;
        padding-top: 20px;
    }
}

/* Chat Section Styles */
.dashboard-chat-section {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
}

.dashboard-chat-section h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.dashboard-chat-form {
    margin-bottom: 20px;
}

.dashboard-chat-input {
    /*width: 100%;*/
    height: 120px;
    padding: 15px;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    font-size: 16px;
    resize: none;
    margin-bottom: 10px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.dashboard-chat-input:focus {
    border-color: #1976D2;
    box-shadow: 0 2px 8px rgba(25, 118, 210, 0.1);
    outline: none;
}

.dashboard-chat-submit-button {
    background: #1976D2;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
}

.dashboard-chat-submit-button:hover {
    background: #1565C0;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dashboard-chat-submit-button:disabled {
    background: #ccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.dashboard-chat-response {
    background: #ffffff;
    padding: 0;
    border-radius: 12px;
    margin: 20px 0;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    overflow: hidden;
    text-align: left;
}

.dashboard-chat-response-header {
    background: #f8f9fa;
    padding: 12px 20px;
    border-bottom: 1px solid #e9ecef;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-chat-response-header-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.dashboard-clear-response {
    background: none;
    border: none;
    color: #999;
    font-size: 12px;
    padding: 4px 8px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s;
}

.dashboard-clear-response:hover {
    opacity: 1;
    color: #666;
}

.dashboard-response-icon {
    font-size: 20px;
}

.dashboard-response-title {
    font-weight: 600;
    color: #2c3e50;
}

.dashboard-chat-response-content {
    padding: 20px;
    color: #2c3e50;
    font-size: 15px;
    line-height: 1.6;
    text-align: left;
}

.dashboard-chat-response-content * {
    text-align: left;
}

.dashboard-chat-response-content p {
    margin: 0 0 16px 0;
    padding: 0;
    text-align: left;
}

.dashboard-chat-response-content ol {
    margin: 0;
    padding: 0 0 0 40px;
    counter-reset: none;
    text-align: left;
}

.dashboard-chat-response-content ol li {
    margin: 8px 0;
    padding: 0;
    text-align: left;
}

.dashboard-chat-response-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
}

.dashboard-chat-response-content ul li {
    position: relative;
    padding-left: 20px;
    margin: 8px 0;
    text-align: left;
}

.dashboard-chat-response-content ul li:before {
    content: "•";
    color: #1976D2;
    position: absolute;
    left: 0;
}

.dashboard-chat-response-highlight {
    background: #e3f2fd;
    border-left: 4px solid #1976D2;
    padding: 12px 16px;
    margin: 10px 0;
    border-radius: 4px;
    text-align: left;
}

/* Loading Styles */
.dashboard-chat-loading {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
    color: #666;
}

.dashboard-loading-spinner {
    width: 20px;
    height: 20px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #1976D2;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.dashboard-suggestions-container {
    margin-top: 20px;
}

.dashboard-suggestion-chip {
    display: inline-block;
    padding: 6px 12px;
    background: #f0f0f0;
    border-radius: 20px;
    margin: 5px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dashboard-suggestion-chip:hover {
    background: #e0e0e0;
}

.dashboard-suggestion-chip.dynamic {
    background: #e8f4ff;
}

/* Dashboard Grid Layout */
.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

/* Common Section Styles */
.dashboard-section {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dashboard-section h3 {
    color: #333;
    margin-bottom: 15px;
    font-size: 18px;
}

/* Progress Bar Styles */
.dashboard-progress-bar {
    width: 100%;
    height: 8px;
    background: #f0f0f0;
    border-radius: 4px;
    overflow: hidden;
    margin: 8px 0;
}

.dashboard-progress {
    height: 100%;
    background: #4CAF50;
    transition: width 0.3s ease;
}

/* Progress Circle Styles */
.dashboard-progress-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    background: #f5f5f5;
    overflow: hidden;
}

.dashboard-progress-circle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: conic-gradient(
        #4CAF50 calc(var(--progress) * 1%),
        #f5f5f5 calc(var(--progress) * 1%)
    );
}

.dashboard-progress-circle::after {
    content: attr(data-progress) '%';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

/* Action Button Styles */
.dashboard-action-button {
    background: #1976D2;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dashboard-action-button:hover {
    background: #1565C0;
}

/* Stats Container Styles */
.dashboard-stats-container {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}

.dashboard-stat-item {
    flex: 1;
    text-align: center;
    padding: 15px;
    background: #f8f9fa;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.dashboard-stat-label {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
}

.dashboard-stat-value {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: auto;
}

.dashboard-stat-subtitle {
    font-size: 13px;
    color: white;
    margin-top: 8px;
    background-color: #4CAF50;
    padding: 4px 8px;
    border-radius: 4px;
    display: inline-block;
    font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .dashboard-stats-container {
        flex-direction: column;
    }
}

/* Quick Expense Entry Styles */
.dashboard-quick-expense-entry {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 10px;
   /* margin-top: 15px; */
}

.dashboard-quick-expense-entry input,
.dashboard-quick-expense-entry select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .dashboard-grid {
        grid-template-columns: 1fr;
    }
    
    .dashboard-stats-container {
        grid-template-columns: 1fr;
    }
    
    .dashboard-quick-expense-entry {
        grid-template-columns: 1fr;
    }
}

.dashboard-retirement-plan {
    margin-top: 15px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
}

.dashboard-retirement-plan p {
    margin: 5px 0;
    color: #333;
}

.dashboard-retirement-stats p {
    margin: 5px 0;
    font-size: 16px;
}

.dashboard-retirement-progress {
    display: flex;
    align-items: center;
    gap: 20px;
}

.dashboard-goals-stats {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}

.dashboard-goal-stat-item {
    flex: 1;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.dashboard-goal-stat-item.active {
    background-color: #fff3e0;
    border: 1px solid #ffb74d;
}

.dashboard-goal-stat-item.paused {
    background-color: #e3f2fd;
    border: 1px solid #64b5f6;
}

.dashboard-goal-stat-item.completed {
    background-color: #e8f5e9;
    border: 1px solid #81c784;
}

.dashboard-goal-stat-item .dashboard-stat-label {
    font-size: 14px;
    color: #666;
}

.dashboard-goal-stat-item .dashboard-stat-value {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.dashboard-goals-progress {
    margin-top: 20px;
}

.dashboard-goal-item {
    background: #f8f9fa;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 8px;
}

.dashboard-goal-name {
    font-weight: 600;
    color: #333;
    margin-bottom: 6px;
    display: block;
}

.dashboard-goal-details {
    font-size: 13px;
    color: #666;
    margin-top: 6px;
    display: block;
}

.dashboard-fund-stats p, 
.dashboard-retirement-stats p,
.dashboard-current-expenses p,
.dashboard-expenses-insights p,
.dashboard-fund-timeline p,
.dashboard-retirement-plan p {
    text-align: left;
    padding-left: 10px;
}

.dashboard-fund-progress,
.dashboard-retirement-progress {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    gap: 20px;
}

.dashboard-fund-stats,
.dashboard-retirement-stats {
    flex: 1;
}

.dashboard-progress-circle {
    flex-shrink: 0;
}

.dashboard-fund-details,
.dashboard-retirement-details {
    padding: 10px 0;
}

.dashboard-expense-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}

.dashboard-action-button.secondary {
    background: #f5f5f5;
    color: #1976D2;
    border: 1px solid #1976D2;
}

.dashboard-action-button.secondary:hover {
    background: #e3f2fd;
}

.dashboard-expenses-section {
    display: flex;
    flex-direction: column;
}

.dashboard-expenses-overview {
    padding: 20px 0 0 0;
    border-top: 1px solid #eee;
    display: flex;
    gap: 30px;
}

.dashboard-expenses-overview-left {
    flex: 0.4; /* 40% width */
}

.dashboard-expenses-overview-right {
    flex: 0.6; /* 60% width */
    padding-left: 30px;
    border-left: 1px solid #eee;
}

.dashboard-expenses-overview h4 {
    color: #333;
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 700;
    text-align: left;
    padding-left: 0;
}

/* Adjust responsive behavior */
@media (max-width: 1200px) {
    .dashboard-expense-form-row {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 768px) {
    .dashboard-expense-form-row {
        grid-template-columns: 1fr;
    }
}

.dashboard-details-button {
    margin-top: auto;
    align-self: flex-start;
}

.dashboard-success-message {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #4CAF50;
    color: white;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    animation: slideIn 0.3s ease-out;
    z-index: 1000;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.dashboard-pyramid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}

.dashboard-pyramid-level {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-bottom: 2px;
    color: white;
    font-weight: 600;
    text-align: center;
    transition: transform 0.3s ease;
    border-radius: 4px;
}

.dashboard-pyramid-level:hover {
    transform: scale(1.02);
}

/* Pyramid levels from top (narrowest) to bottom (widest) */
.dashboard-pyramid-level.selected:nth-child(1) { width: 30%; background-color: #E0BBE4; }
.dashboard-pyramid-level.selected:nth-child(2) { width: 40%; background-color: #C7CEEA; }
.dashboard-pyramid-level.selected:nth-child(3) { width: 50%; background-color: #B5EAD7; }
.dashboard-pyramid-level.selected:nth-child(4) { width: 60%; background-color: #E2F0CB; }
.dashboard-pyramid-level.selected:nth-child(5) { width: 70%; background-color: #FFDAC1; }
.dashboard-pyramid-level.selected:nth-child(6) { width: 80%; background-color: #FFB7B2; }
.dashboard-pyramid-level.selected:nth-child(7) { width: 90%; background-color: #FF9AA2; }

/* Unselected levels in grey */
.dashboard-pyramid-level.unselected:nth-child(1) { width: 30%; background-color: #D3D3D3; }
.dashboard-pyramid-level.unselected:nth-child(2) { width: 40%; background-color: #D3D3D3; }
.dashboard-pyramid-level.unselected:nth-child(3) { width: 50%; background-color: #D3D3D3; }
.dashboard-pyramid-level.unselected:nth-child(4) { width: 60%; background-color: #D3D3D3; }
.dashboard-pyramid-level.unselected:nth-child(5) { width: 70%; background-color: #D3D3D3; }
.dashboard-pyramid-level.unselected:nth-child(6) { width: 80%; background-color: #D3D3D3; }
.dashboard-pyramid-level.unselected:nth-child(7) { width: 90%; background-color: #D3D3D3; }
 