.short-term-savings {
 /* max-width: 800px; */
  margin: 0 auto;
  /* padding: 20px; */
  font-family: Arial, sans-serif;
}

h2 {
  color: #333;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.input-row {
  display: flex;
  gap: 10px; /* Space between input fields */
}

.input-row input {
  flex: 1; /* Make inputs take equal space */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-row input::placeholder {
  color: gray;
  opacity: 1; /* Ensures the placeholder is fully opaque */
}

form button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

form button:hover {
  background-color: #45a049;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: left;
}

table th {
  background-color: #e8f4ff; /* Green header */
  color: #000000;
  font-weight: bold;
}

table tr:nth-child(even) {
  background-color: #f9f9f9; /* Light gray for even rows */
}

table tr:hover {
  background-color: #f1f1f1; /* Light gray on hover */
}

table td {
  background-color: #ffffff; /* White background for cells */
  transition: background-color 0.3s;
}

table td input {
  width: 100%;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
}

table td input:focus {
  border-color: #4CAF50;
  outline: none; /* Remove default outline */
}

.goal-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
}

.goal-card h3 {
  margin-top: 0;
}

.goal-card p {
  margin: 10px 0;
  color: #666;
}

.remove-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-btn:hover {
  background-color: #d32f2f;
}
.save-details {
  margin-top: 20px;
  text-align: center;
}

.save-details button {
  padding: 10px 20px;
  background-color: #007bff; /* Blue button */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-details button:hover {
  background-color: #4CAF50; /* Darker blue on hover */
}

.total-contribution {
  margin-top: 10px;
  font-size: 1.2em;
  color: #333;
}

/* Style for paused goals */
.short-term-savings .paused-goal {
    background-color: #d3d3d3 !important; /* Grey background for paused goals */
}

/* Style for the paused goals section */
.short-term-savings .paused-goals {
    margin-top: 20px; /* Space above the paused goals section */
}

/* Style for the input fields */
.short-term-savings .input-row input {
    width: 100%; /* Full width for input fields */
    padding: 8px; /* Padding for better appearance */
    margin: 5px 0; /* Margin for spacing */
}

/* Style for the table */
.short-term-savings table {
    width: 100%; /* Full width for the table */
    border-collapse: collapse; /* Collapse borders */
}

.short-term-savings th, .short-term-savings td {
    border: 1px solid #ccc; /* Border for table cells */
    padding: 10px; /* Padding for table cells */
    text-align: left; /* Left align text */
}

/* Style for buttons */
.short-term-savings .save-details button {
    padding: 10px 15px; /* Padding for buttons */
    background-color: #007bff; /* Green background */
    color: white; /* White text */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor */
}

.short-term-savings .save-details button:hover {
    background-color: #45a049; /* Darker green on hover */
}

/* Other specific styles for ShortTermSavings can be added here */

/* Dropdown styling */
select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; /* Make dropdown full width */
    cursor: pointer; /* Change cursor to pointer */
}

/* Optional: Style for the priority column */
/* th:nth-child(1), td:nth-child(1) {
   width: 100px; } /* Set a fixed width for the priority column */

/* General styles for the component */
.short-term-savings {
    font-family: Arial, sans-serif;
    /* margin: 20px; */
}

/* Header styles */
h2 {
    color: #333;
    margin-bottom: 20px;
}

h3 {
    cursor: pointer;
    color: black; /* White text */
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    transition: background-color 0.3s;
}

/* h3:hover {
    background-color: #4caf50; 
} */

/* Table styles */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2; /* Light gray background for headers */
    color: #333;
}

tr:nth-child(even) {
    background-color: #f9f9f9; /* Zebra striping for rows */
}

tr:hover {
    background-color: #f1f1f1; /* Highlight row on hover */
}

/* Save button styles */
.save-details {
    margin-top: 20px;
}

.save-details button {
    background-color: #007bff; /* Light teal background */
    color: white; /* White text */
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-details button:hover {
    background-color: #45a049; /* Darker teal on hover */
}

/* Dropdown table styles */
.dropdown-table {
    display: none; /* Initially hidden */
}

.dropdown-table.show {
    display: table; /* Show when active */
}

/* Add these styles for the description section */
.short-term-savings-description {
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 32px;
  border: 1px solid #007bff;
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.1);
}

.short-term-savings-description-header {
  text-align: center;
  margin-bottom: 20px;
}

.short-term-savings-description-header h2 {
  color: #1565c0;
  font-size: 1.8rem;
  margin-bottom: 8px;
}

.short-term-savings-description-subtitle {
  color: #1976d2;
  font-size: 1.1rem;
  margin: 0;
}

.short-term-savings-description-features {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 12px;
  margin-top: 20px;
  padding: 0 12px;
}

.short-term-savings-feature-item {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: white;
  padding: 10px 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.1);
  transition: transform 0.2s ease;
}

.short-term-savings-feature-item:hover {
  transform: translateY(-2px);
}

.short-term-savings-feature-icon {
  font-size: 1.2rem;
}

.short-term-savings-feature-item span:not(.short-term-savings-feature-icon) {
  color: #1976d2;
  font-weight: 500;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .short-term-savings-description-features {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .short-term-savings-feature-item {
    flex: 1 1 calc(50% - 12px);
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .short-term-savings-feature-item {
    flex: 1 1 100%;
  }
}

/* Update ALL table header backgrounds to #e8f4ff */

.short-term-savings-goal-table th,
.short-term-savings-summary-table th,
.short-term-savings-strategy-table th,
.short-term-savings-details-table th,
.short-term-savings-input-table th,
.short-term-savings-results-table th,
.short-term-savings-comparison-table th,
.short-term-savings-breakdown-table th,
.short-term-savings-analysis-table th,
.short-term-savings-options-table th,
.short-term-savings-schedule-table th,
.short-term-savings-overview-table th {
    background-color: #e8f4ff;
    color: #000000;
    font-weight: 600;
    padding: 12px 8px;
    text-align: left;
    border: 1px solid #ddd;
    font-size: 14px;
    white-space: normal;
    vertical-align: middle;
    line-height: 1.3;
    height: auto;
}

/* If there are any specific overrides needed for certain tables, add them below */
.short-term-savings-input-table th {
    min-height: 50px;
    padding: 12px 10px;
}

/* Add padding to forms */
.short-term-savings-form {
    padding: 20px;
}

/* Add padding to table containers */
.short-term-savings-table-container {
    padding: 20px;
}

/* If you have specific tables that need direct padding */
.short-term-savings-goal-table,
.short-term-savings-summary-table,
.short-term-savings-strategy-table,
.short-term-savings-details-table,
.short-term-savings-input-table,
.short-term-savings-results-table,
.short-term-savings-comparison-table,
.short-term-savings-breakdown-table,
.short-term-savings-analysis-table,
.short-term-savings-options-table,
.short-term-savings-schedule-table,
.short-term-savings-overview-table {
    padding: 20px;
}

/* Dropdown section styling */
.goals-dropdown-section {
  background: white;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid #e0e0e0;
}

/* Dropdown header styling */
.goals-dropdown-header {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  cursor: pointer;
  transition: all 0.3s ease;
}

.goals-dropdown-header:hover {
  background: linear-gradient(135deg, #bbdefb, #90caf9);
}

/* Different colors for different sections */
.completed-goals .goals-dropdown-header {
  background: linear-gradient(135deg, #c8e6c9, #a5d6a7); /* Green gradient for completed */
}

.paused-goals .goals-dropdown-header {
  background: linear-gradient(135deg, #fff3e0, #ffe0b2); /* Orange gradient for paused */
}

.abandoned-goals .goals-dropdown-header {
  background: linear-gradient(135deg, #ffebee, #ffcdd2); /* Red gradient for abandoned */
}

/* Header title styling */
.goals-dropdown-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1565c0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Icons for different sections */
.goals-dropdown-title::before {
  font-size: 1.2rem;
}

.completed-goals .goals-dropdown-title::before {
  content: '🎉';
}

.paused-goals .goals-dropdown-title::before {
  content: '⏸️';
}

.abandoned-goals .goals-dropdown-title::before {
  content: '🚫';
}

/* Arrow icon styling */
.dropdown-arrow {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

/* Content area styling */
.goals-dropdown-content {
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.goals-dropdown-content.open {
  max-height: 500px;
  padding: 20px;
}

/* Table styling within dropdowns */
.goals-dropdown-content table {
  width: 100%;
  margin: 0;
  border: none;
}

.goals-dropdown-content th {
  background-color: #e8f4ff;
  color: #333;
  font-weight: 600;
  padding: 12px;
}

.goals-dropdown-content td {
  padding: 12px;
  border-bottom: 1px solid #eee;
}

/* Empty state styling */
.goals-empty-state {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}
