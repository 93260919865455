/* Update color variables */
:root {
  --primary-color: #007bff;
  --primary-dark: #1976D2;
  --primary-light: #BBDEFB;
  --primary-lighter: #E3F2FD;
  --text-on-primary: #FFFFFF;
  --error-color: #F44336;
  --border-color: #90CAF9;
}

/* Common Layout Elements */
.page-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 60px;
  box-sizing: border-box;
}

.content-container {
  width: 100%;
  padding-top: 60px;
}

.progress-section {
  text-align: center;
  margin-bottom: 30px;
}

/* SpendAnalyzer Specific Styles */
.spend-analyzer-wrapper {
  width: 100%;
}

.spend-analyzer-page {
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 220px); */
}

.scrollable-content {
  width: 100%;
  height: auto;
  min-height: 200px;
  max-height: 60vh;
  overflow-y: auto;
  padding: 20px;
}

.spend-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Table Styles */
.spend-analyzer-wrapper .spend-analyzer-table {
  table-layout: fixed;
  width: 980px;
  border-collapse: collapse;
  margin: 0;
  border: 1px solid #BBDEFB;
}

.spend-analyzer-wrapper .spend-analyzer-table th,
.spend-analyzer-wrapper .spend-analyzer-table td {
  box-sizing: border-box;
  padding: 4px;
  height: 40px;
  border: 1px solid #BBDEFB;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.spend-analyzer-wrapper .spend-analyzer-table th {
  background-color: #007bff;
  font-weight: bold;
  border-bottom: 2px solid #1976D2;
  color: white;
}

/* Column Widths */
.spend-analyzer-wrapper .spend-analyzer-table th:nth-child(1),
.spend-analyzer-wrapper .spend-analyzer-table td:nth-child(1) {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  text-align: left;
}

.spend-analyzer-wrapper .spend-analyzer-table th:nth-child(2),
.spend-analyzer-wrapper .spend-analyzer-table td:nth-child(2) {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  text-align: left;
}

.spend-analyzer-wrapper .spend-analyzer-table th:nth-child(3),
.spend-analyzer-wrapper .spend-analyzer-table td:nth-child(3) {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  text-align: right;
}

.spend-analyzer-wrapper .spend-analyzer-table th:nth-child(4),
.spend-analyzer-wrapper .spend-analyzer-table td:nth-child(4) {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

/* Input Styles */
.spend-analyzer-wrapper .input-select, 
.spend-analyzer-wrapper .input-text,
.spend-analyzer-wrapper .input-number {
  width: 60%;
  height: 30px;
  padding: 0 4px;
  border: none;
  background: #ffffff;
  font-size: 18px;
  box-sizing: border-box;
}

.spend-analyzer-wrapper .input-number {
  text-align: right;
}

.spend-analyzer-wrapper .input-select,
.spend-analyzer-wrapper .input-text {
  text-align: left;
}

/* Button Styles */
.spend-analyzer-wrapper .delete-button {
  background: none;
  border: none;
  color: #F44336;
  cursor: pointer;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spend-analyzer-wrapper .delete-button:hover {
  color: #D32F2F;
}

.spend-analyzer-wrapper .add-category-button, 
.spend-analyzer-wrapper .analyze-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.spend-analyzer-wrapper .add-category-button:hover, 
.spend-analyzer-wrapper .analyze-button:hover {
  background-color: #1976D2;
}

/* Custom Category Styles */
.spend-analyzer-wrapper .custom-category {
  display: flex;
  margin-bottom: 15px;
}

.spend-analyzer-wrapper .add-custom-button {
  margin-left: 10px;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.spend-analyzer-wrapper .add-custom-button:hover {
  background-color: #1976D2;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .spend-analyzer-wrapper .spend-analyzer-table {
    width: 100% !important;
  }
  
  .spend-analyzer-wrapper .spend-analyzer-table th:nth-child(1),
  .spend-analyzer-wrapper .spend-analyzer-table td:nth-child(1),
  .spend-analyzer-wrapper .spend-analyzer-table th:nth-child(2),
  .spend-analyzer-wrapper .spend-analyzer-table td:nth-child(2) {
    width: 35%;
  }
}

/* After the common layout elements and before spend-analyzer-wrapper */

.content-container h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.spend-analyzer-description {
  margin-bottom: 30px;
  color: #666;
  line-height: 1.5;
}

/* These styles are already in place */
.spend-analyzer-wrapper .input-select {
  width: 100%;
  height: 26px;
  padding: 0 4px;
  border: none;
  background: transparent;
  font-size: 14px;
  box-sizing: border-box;
  text-align: left;
}

/* Monthly Income Section Styles */
.monthly-income-section {
  margin: 10px auto;
  text-align: center;
  padding: 15px;
  background-color: #E3F2FD;
  border-radius: 8px;
  max-width: 500px;
  border: 1px solid #BBDEFB;
}

.monthly-income-section p {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  color: inherit;
}

.monthly-income-section input {
  min-width: 300px;
  height: 40px;
  margin-bottom: 10px;
  font-size: 16px;
  padding: 0 10px;
  background-color: #ffffff;
  border: 1px solid #BBDEFB;
  border-radius: 4px;
  font-family: inherit;
}

.monthly-income-section button {
  min-width: 300px;
  height: 40px;
  font-size: 16px;
  font-family: inherit;
  display: block;
  margin: 0 auto;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.monthly-income-section button:hover {
  background-color: #1976D2;
}

.error-message {
  color: #F44336;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

/* Update generic button styles to be specific */
.spend-analyzer-add-button,
.spend-analyzer-confirm-button {
  width: 300px;
  height: 40px;
  font-size: 16px;
  font-family: inherit;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.spend-analyzer-add-button:hover,
.spend-analyzer-confirm-button:hover {
  background-color: #1976D2;
}

/* Monthly Income Section */
.spend-analyzer-income-section {
  margin: 10px auto;
  text-align: center;
  padding: 15px;
  background-color: #E3F2FD;
  border-radius: 8px;
  max-width: 500px;
  border: 1px solid #BBDEFB;
}

.spend-analyzer-income-section p {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  color: inherit;
}

.spend-analyzer-income-section input {
  width: 300px;
  height: 40px;
  margin-bottom: 10px;
  font-size: 16px;
  padding: 0 10px;
  background-color: #ffffff;
  border: 1px solid #BBDEFB;
  border-radius: 4px;
  font-family: inherit;
}

.spend-analyzer-income-section button {
  width: 300px;
  height: 40px;
  font-size: 16px;
  font-family: inherit;
  display: block;
  margin: 0 auto;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.spend-analyzer-income-section button:hover {
  background-color: #1976D2;
}

.spend-analyzer-error {
  color: #F44336;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

/* Update button container for center alignment */
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

/* Update button styles to be specific to SpendAnalyzer */
.spend-analyzer-add-button,
.analyze-button {
  width: 300px !important;
  min-width: 300px;
  height: 40px;
  font-size: 16px;
  font-family: inherit;
  margin: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

/* Add small spacing between buttons */
.analyze-button {
  margin-top: 5px;
}

/* Add transitions for interactive elements */
.spend-analyzer-wrapper button,
.input-select,
.input-text,
.input-number {
  transition: all 0.3s ease;
}

/* Table hover effects */
.spend-analyzer-wrapper .spend-analyzer-table tr:hover {
  background-color: #E3F2FD;
}

/* Input container styling */
.input-container {
  position: relative;
}

.input-container input {
  border: 1px solid #BBDEFB;
}

.input-container input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
}

/* Main heading styles */
.MuiTypography-h6 {
  color: #2c3e50;
  margin-bottom: 24px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
}

/* Subtitle styles for "Analyze spends for" text */
.MuiTypography-subtitle1 {
  color: #34495e;
  font-size: 1rem;
  font-weight: 500;
}

/* Container for the date filters */
.date-filter-container {
  background-color: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Select dropdown styles */
.MuiFormControl-root {
  margin: 0 8px;
}

.MuiSelect-select {
  min-width: 120px;
  background-color: white;
}

/* Hover effect for select dropdowns */
.MuiSelect-select:hover {
  background-color: #f5f5f5;
}

/* MenuItem styles when hovered */
.MuiMenuItem-root:hover {
  background-color: #e8f0fe;
}