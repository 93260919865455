.conclusion-financial-roadmap {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.conclusion-roadmap-header {
  text-align: center;
  margin-bottom: 2rem;
}

.conclusion-roadmap-title {
  font-size: 2rem;
  color: #2196f3;
  margin-bottom: 1rem;
}

.conclusion-roadmap-subtitle {
  font-size: 1.2rem;
  color: #666;
  position: relative;
  display: inline-block;
  padding: 0.5rem 1.5rem;
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(33, 150, 243, 0.1);
  border: 1px solid rgba(33, 150, 243, 0.2);
  animation: subtitleFadeIn 0.8s ease-out;
}

.conclusion-roadmap-subtitle::before,
.conclusion-roadmap-subtitle::after {
  content: '✨';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.4rem;
  color: #2196f3;
  animation: sparkle 1.5s infinite;
}

.conclusion-roadmap-subtitle::before {
  left: -2rem;
}

.conclusion-roadmap-subtitle::after {
  right: -2rem;
}

@keyframes subtitleFadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes sparkle {
  0%, 100% {
    opacity: 1;
    transform: translateY(-50%) scale(1);
  }
  50% {
    opacity: 0.6;
    transform: translateY(-50%) scale(0.8);
  }
}

.conclusion-budget-table-container {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.conclusion-budget-table-container h2 {
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.conclusion-budget-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1rem;
}

.conclusion-budget-table th,
.conclusion-budget-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.conclusion-budget-table th {
  background-color: #2196f3;
  color: white;
  font-weight: 600;
}

.conclusion-progress-summary {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.conclusion-progress-summary h2 {
  color: #333;
  margin: 0 0 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.conclusion-actions-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 2rem;
}

.conclusion-actions-list li {
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  color: #444;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s ease;
}

.conclusion-actions-list li::before {
  display: none;
}

.conclusion-increase {
  color: #d32f2f;
  font-weight: 500;
}

.conclusion-decrease {
  color: #2e7d32;
  font-weight: 500;
}

.conclusion-negative-income {
  background-color: #ffebee;
  color: #d32f2f;
}

.conclusion-positive-income {
  background-color: #e8f5e9;
  color: #2e7d32;
}

/* Loading state styles */
.conclusion-loading-summary {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.conclusion-loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #2196f3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Error state styles */
.conclusion-error-message {
  background: #fff3f3;
  border-left: 4px solid #d32f2f;
  padding: 1rem;
  margin: 1rem 0;
  color: #d32f2f;
  border-radius: 4px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .conclusion-financial-roadmap {
    padding: 1rem;
  }

  .conclusion-budget-table-container,
  .conclusion-progress-summary {
    padding: 1rem;
  }

  .conclusion-roadmap-title {
    font-size: 1.5rem;
  }

  .conclusion-roadmap-subtitle {
    font-size: 1rem;
    padding: 0.4rem 1.2rem;
  }

  .conclusion-roadmap-subtitle::before,
  .conclusion-roadmap-subtitle::after {
    font-size: 1.2rem;
  }

  .conclusion-roadmap-subtitle::before {
    left: -1.5rem;
  }

  .conclusion-roadmap-subtitle::after {
    right: -1.5rem;
  }

  .conclusion-budget-table th,
  .conclusion-budget-table td {
    padding: 8px 12px;
    font-size: 0.9rem;
  }

  .conclusion-progress-summary h2 {
    font-size: 1.2rem;
  }

  .conclusion-actions-list li {
    font-size: 0.9rem;
  }
}

/* Print styles */
@media print {
  .conclusion-financial-roadmap {
    padding: 0;
  }

  .conclusion-budget-table-container,
  .conclusion-progress-summary {
    box-shadow: none;
    border: 1px solid #e0e0e0;
  }

  .conclusion-budget-table th {
    background-color: #f5f5f5;
    color: #333;
  }
}

.conclusion-progress-item, .conclusion-improvement-item {
  background: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s ease;
}

.conclusion-progress-item:hover, .conclusion-improvement-item:hover {
  transform: translateY(-2px);
}

.conclusion-progress-item {
  border-left: 4px solid #2196f3;
}

.conclusion-improvement-item {
  border-left: 4px solid #ff9800;
}

.conclusion-progress-timeline-container {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  margin: 2rem 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.conclusion-timeline-header {
  text-align: center;
  margin-bottom: 2rem;
}

.conclusion-timeline-header h2 {
  color: #2196f3;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.conclusion-timeline-subtext {
  color: #666;
  font-size: 1.1rem;
}

.conclusion-timeline-progress {
  position: relative;
  padding: 2rem 0;
}

.conclusion-progress-bar {
  height: 8px;
  background: #e0e0e0;
  border-radius: 4px;
  position: relative;
  margin: 3rem 0;
}

.conclusion-progress-fill {
  position: absolute;
  height: 100%;
  background: linear-gradient(90deg, #4CAF50, #2196f3);
  border-radius: 4px;
  transition: width 1s ease-in-out;
}

.conclusion-milestone {
  position: absolute;
  transform: translateX(-50%);
  top: -25px;
}

.conclusion-milestone-icon {
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.conclusion-milestone.completed .conclusion-milestone-icon {
  background: #4CAF50;
  color: white;
}

.conclusion-milestone.in-progress .conclusion-milestone-icon {
  background: #FFA726;
  color: white;
}

.conclusion-milestone.not-started .conclusion-milestone-icon {
  background: #E0E0E0;
  color: #666;
}

.conclusion-milestone-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 200px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 10;
}

.conclusion-milestone:hover .conclusion-milestone-tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-10px);
}

.conclusion-milestone-tooltip h3 {
  color: #333;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.conclusion-milestone-tooltip p {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}

.conclusion-motivation-text {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem;
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  border-radius: 8px;
  animation: fadeIn 0.8s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add confetti animation for completed milestones */
@keyframes conclusion-confetti {
  0% { transform: translateY(0) rotate(0deg); }
  100% { transform: translateY(-20px) rotate(360deg); }
}

.conclusion-milestone.completed::after {
  content: '🎉';
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  animation: conclusion-confetti 1s ease infinite alternate;
}

.conclusion-actions-list li:hover {
  transform: translateY(-2px);
}

.conclusion-download-section {
  text-align: center;
  margin: 2rem 0;
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.conclusion-download-button {
  background: #2196f3;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.conclusion-download-button::before {
  content: "📊";
  font-size: 1.2rem;
}

.conclusion-download-button:hover {
  background: #1976d2;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(33, 150, 243, 0.2);
}

@media (max-width: 768px) {
  .conclusion-download-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

.conclusions-navigation {
  margin-top: 30px;
  text-align: center;
}

.dashboard-button {
  background: #1976D2;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dashboard-button:hover {
  background: #1565C0;
}
