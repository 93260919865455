.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.progress-bar-container {
  position: sticky;
  top: 64px;
  z-index: 10;
  height: 70px;
  background-color: white;
}

.page-container {
  position: relative;
  top: 10px;
  z-index: 1;
}

.content-container {
  flex: 1;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px;
}

body {
  margin: 0;
  padding: 0;
}
