.spend-analysis-summary {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 180px); /* Adjust based on your layout */
}

/*h2, h3 {
  text-align: center;
  color: #333;
  margin-bottom: 10px;
}*/

.summary-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  flex: 1;
}

.pie-chart {
  width: 65%;
}

.spend-breakdown {
  width: 60%;
  margin: 20px 0;
}

.spend-breakdown table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Force fixed width columns */
}

.spend-breakdown thead tr,
.spend-breakdown tbody tr,
.spend-breakdown tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.spend-breakdown th,
.spend-breakdown td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word;
  vertical-align: middle;
}

/* Fixed widths for columns */
.spend-breakdown th:first-child,
.spend-breakdown td:first-child {
  width: 40%;
  text-align: left;
}

.spend-breakdown th:nth-child(2),
.spend-breakdown td:nth-child(2) {
  width: 30%;
  text-align: right;
}

.spend-breakdown th:nth-child(3),
.spend-breakdown td:nth-child(3) {
  width: 30%;
  text-align: right;
}

/* Header styling */
.spend-breakdown thead th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

/* Total row styling */
.spend-breakdown tfoot tr {
  border-top: 2px solid #ddd;
  font-weight: bold;
}

.spend-breakdown tfoot td {
  border-bottom: none;
}

.insights {
  margin-bottom: 20px;
}

.insights ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.insights li {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
}

.insights li.positive {
  background-color: #ebffeb;
  color: #008900;
}

.insights li.negative {
  background-color: #FFEBEE;
  color: #C62828;
}

.financial-goals {
  margin-top: 20px;
}

.financial-goals table {
  width: 800px !important;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.financial-goals table th,
.financial-goals table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.financial-goals table th {
  background-color: #f8f9fa;
  font-weight: bold;
  text-align: center;
}

.financial-goals table td:last-child {
  text-align: right;
}

.take-action-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 50px;
}

.take-action-button:hover {
  background-color: #4CAF50;
}

.chatbot {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
}

.chat-messages {
  height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.message {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
}

.user {
  background-color: #E3F2FD;
  text-align: right;
}

.assistant {
  background-color: #f0f0f0;
}

.chatbot form {
  display: flex;
}

.chatbot input {
  flex-grow: 1;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .summary-content {
    flex-direction: column;
  }

  .pie-chart,
  .spend-breakdown {
    width: 100%;
  }

  .pie-chart {
    margin-bottom: 20px;
  }
}

/* Add only these styles for financial goals table headers */
.financial-goals table th {
  text-align: center;
}

/* Original styles for SpendAnalysisSummary tables */
.spend-summary-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.spend-summary-table th,
.spend-summary-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.spend-summary-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

/* Amount columns right-aligned */
.spend-summary-table td:nth-child(2),
.spend-summary-table th:nth-child(2) {
  text-align: right;
}

/* Category grouping styles */
.category-group {
  margin-bottom: 30px;
  width: 400px;
}

.category-group h3 {
  margin-bottom: 15px;
  color: #333;
}

/* Summary section styles */
.summary-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #E3F2FD;
  border-radius: 8px;
}

.summary-section h2 {
  color: #333;
  margin-bottom: 20px;
}

/* Chart container */
.chart-container {
  margin: 30px 0;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.1);
}

/* Navigation buttons */
.nav-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.back-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #4CAF50;
}

/* Percentage and amount display */
.amount-cell {
  text-align: right;
  white-space: nowrap;
}

.percentage {
  color: #1976D2;
  font-size: 0.9em;
  margin-left: 10px;
}

.spend-table {
  width: 400px;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
}

.spend-table th,
.spend-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spend-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

/* Category column (50%) */
.spend-table th:first-child,
.spend-table td:first-child {
  width: 50%;
}

/* Amount column (30%) */
.spend-table th:nth-child(2),
.spend-table td:nth-child(2) {
  width: 30%;
  text-align: right;
}

/* Percentage column (20%) */
.spend-table th:last-child,
.spend-table td:last-child {
  width: 20%;
  text-align: right;
}

.category-group {
  margin-bottom: 30px;
  width: 600px; /* Match table width */
}

.category-group h3 {
  margin-bottom: 15px;
  color: #333;
}

.percentage {
  color: #666;
  font-size: 0.9em;
  margin-left: 10px;
}

/* Container to center the content */
.spend-analysis-summary {
  width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.spend-table th:last-child,
.spend-table td:last-child {
  width: 30%;
}

/* Specific styles for spend breakdown table */
.spend-breakdown-table {
  width: 350px;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
}

.spend-breakdown-table th,
.spend-breakdown-table td {
  padding: 8px 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spend-breakdown-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

/* Column widths for spend breakdown */
.spend-breakdown-table th:first-child,
.spend-breakdown-table td:first-child {
  width: 50%;
}

.spend-breakdown-table th:nth-child(2),
.spend-breakdown-table td:nth-child(2) {
  width: 30%;
  text-align: right;
}

.spend-breakdown-table th:last-child,
.spend-breakdown-table td:last-child {
  width: 20%;
  text-align: right;
}

/* Make the selector more specific and add !important if needed */
.spend-breakdown table.spend-breakdown-table {
  width: 450px !important;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
}

/* Check and remove any conflicting styles from: */
/* 1. The .spend-breakdown class */
.spend-breakdown {
  width: 60%;  /* Remove or adjust this if it exists */
}

/* 2. General table styles */
table {
  width: auto;  /* Remove any fixed width here */
}

/* Financial goals table specific styles */
.summary-financial-goals-table {
  width: 100% !important;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
  border: 1px solid #BBDEFB;
  font-family: inherit;
}

.summary-financial-goals-table thead th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  text-align: center;  padding: 12px;
  border-bottom: 2px solid #1976D2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.summary-financial-goals-table th:nth-child(1),
.summary-financial-goals-table td:nth-child(1) {
  width: 25%;
}

.summary-financial-goals-table th:nth-child(2),
.summary-financial-goals-table td:nth-child(2) {
  width: 25%;
}

.summary-financial-goals-table th:nth-child(3),
.summary-financial-goals-table td:nth-child(3) {
  width: 25%;
}

.summary-financial-goals-table th:nth-child(4),
.summary-financial-goals-table td:nth-child(4) {
  width: 25%;
}

.summary-financial-goals-table td:last-child {
  text-align: right;
}

/* Add styles for disabled goals */
.summary-financial-goals-table .disabled-goal {
  background-color: #F5F5F5;
  color: #90A4AE;
}

.summary-financial-goals-table .disabled-goal input[type="checkbox"] {
  opacity: 0.5;
  cursor: not-allowed;
}

.summary-financial-goals-table .disabled-text {
  font-size: 0.8em;
  color: #90A4AE;
  margin-left: 8px;
  font-style: italic;
}

/* Ensure the table cell is wide enough for the additional text */
.summary-financial-goals-table td:last-child {
  min-width: 120px;
}

/* Add hover effects for interactive elements */
.spend-table tr:hover,
.spend-breakdown-table tr:hover {
  background-color: #E3F2FD;
}

/* Add subtle borders */
.spend-table td,
.spend-breakdown-table td {
  border-bottom: 1px solid #BBDEFB;
}

/* Add transitions for smooth interactions */
.take-action-button,
.back-button {
  transition: all 0.3s ease;
}

/* Update disabled states */
.summary-financial-goals-table .disabled-text {
  color: #90A4AE;
}

.summary-financial-goals-table .disabled-goal input[type="checkbox"] {
  opacity: 0.5;
}
