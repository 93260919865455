.long-term-savings {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.form-container {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 6px;
  font-weight: 600;
  color: #333;
  font-size: 14px;
}

.form-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  border-color: #4CAF50;
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  margin-top: 5px;
}

.submit-button:hover {
  background-color: #45a049;
}

.goals-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-x: auto;
}

.goals-table th,
.goals-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  vertical-align: top;
}

.goals-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #333;
}

.goals-table tr:hover {
  background-color: #f5f5f5;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c82333;
}

.no-goals {
  text-align: center;
  color: #666;
  padding: 20px;
}

.save-details-container {
  margin-top: 20px;
  text-align: right;
}

.save-details-button {
  background-color: #4CAF50;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.save-details-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .long-term-savings {
    padding: 10px;
  }

  .goals-table {
    font-size: 14px;
  }

  .goals-table th,
  .goals-table td {
    padding: 8px;
  }
}

@media (max-width: 1024px) {
  .goals-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .goals-table th,
  .goals-table td {
    min-width: 100px;
    white-space: nowrap;
  }
  
  .goals-table td:nth-child(7) {
    min-width: 100px;
  }
}

.save-success {
  color: #4CAF50;
  margin-left: 10px;
  font-weight: 500;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.original-timeframe {
  color: #666;
  font-size: 12px;
  margin-left: 5px;
  display: block;
}
