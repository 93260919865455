.fund-plan-page {
    background: white;
    border-radius: 16px;
    padding: 30px;
    margin-top: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 30px auto;
    border: 1px solid #e0e0e0;
  }
  
  .fund-plan-page h2 {
    color: #1565C0;
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .selected-fund-info {
    background: linear-gradient(135deg, #e3f2fd, #bbdefb);
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 24px;
    text-align: center;
    border: 1px solid #90caf9;
  }
  
  .selected-fund-info h3 {
    color: #1565C0;
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  
  .selected-fund-info p {
    color: #1976D2;
    font-size: 1.1rem;
    font-weight: 500;
  }
  
  .contribution-section {
    margin-bottom: 24px;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 12px;
  }
  
  .contribution-section label {
    display: block;
    margin-bottom: 10px;
    color: #007bff;
    font-size: 1rem;
  }
  
  .input-number {
    width: 100%;
    padding: 12px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .input-number:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .build-emergency-fund-section {
    margin-bottom: 24px;
    text-align: center;
  }
  
  .build-emergency-fund-section p {
    color: #666;
    margin-bottom: 16px;
  }
  
  .build-options {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-bottom: 24px;
  }
  
  .build-option-button-time,
  .build-option-button-amount {
    padding: 12px 24px;
    border: 2px solid #007bff;
    border-radius: 8px;
    background: white;
    color: #007bff;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .build-option-button-time.active,
  .build-option-button-amount.active {
    background: #007bff;
    color: white;
  }
  
  .calculate-time-button {
    width: 100%;
    padding: 12px;
    margin-top: 16px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: background 0.3s ease;
  }
  
  .calculate-time-button:hover {
    background: #4CAF50;
  }
  
  .time-result {
    margin-top: 20px;
    padding: 16px;
    background: #e3f2fd;
    border-radius: 8px;
    text-align: center;
  }
  
  .time-result h3 {
    color: #1565C0;
    font-size: 1.1rem;
    margin: 0;
  }
  
  .add-to-budget-button {
    width: 100%;
    padding: 14px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: 24px;
    transition: background 0.3s ease;
  }
  
  .add-to-budget-button:hover {
    background: #4CAF50;
  }
  
  .success-message {
    background: #e8f5e9;
    color: #2e7d32;
    padding: 16px;
    border-radius: 8px;
    margin-top: 16px;
    text-align: center;
    font-weight: 500;
    border: 1px solid #a5d6a7;
    animation: fadeIn 0.5s ease-in;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .fund-plan-page {
      padding: 20px;
    }
  }
  