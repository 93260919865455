.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.banner {
  background-size: cover;
  background-position: center;
  height: 200px; /* You can adjust this value if needed */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  margin-bottom: 20px; /* Reduce this value to decrease the space */
}

.banner h1 {
  font-size: 2.5em;
  margin: 0;
}

.home-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.home-content h2 {
  font-size: 2em;
  margin-top: 0; /* Remove top margin */
  margin-bottom: 15px; /* Reduced bottom margin */
}

.home-content p {
  font-size: 1.2em;
  margin-bottom: 20px; /* Reduced margin */
}

.designer-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

h2 {
  color: #333;
  margin-bottom: 10px;
}

p {
  color: #666;
  margin-bottom: 20px;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2196F3;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #45a049;
}

footer {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-container {
  width: 50%;
  max-height: 600px;
  overflow: hidden;
  margin-bottom: 2rem;
}

.banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.App-header {
  text-align: center;
  padding: 2rem;
}

.banner {
  background-size: cover;
  background-position: center;
  height: 200px; /* Adjust this value as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
}

.banner h1 {
  color: white;
  font-size: 2.5em;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.chatbot {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
}

.chat-messages {
  height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.message {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
}

.user {
  background-color: #e6f2ff;
  text-align: right;
}

.assistant {
  background-color: #f0f0f0;
}

.chatbot form {
  display: flex;
}

.chatbot input {
  flex-grow: 1;
  margin-right: 5px;
}
