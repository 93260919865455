.chatbot {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 300px;
    height: 400px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.3s ease;
}

.chatbot.closed {
    height: 50px;
}

.chatbot-toggle {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    flex-shrink: 0;
}

.chatbot-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px); /* Subtract the height of the toggle button */
    overflow: hidden;
}

.messages {
    flex: 1;
    overflow-y: scroll;
    padding: 10px;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 60px); /* Subtract the height of the form */
}

.message {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 5px;
    max-width: 80%;
    word-wrap: break-word;
}

.user {
    background-color: #e6f2ff;
    align-self: flex-end;
}

.assistant {
    background-color: #ffffff;
    align-self: flex-start;
}

.chatbot form {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    flex-shrink: 0;
}

.chatbot input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
}

.chatbot button {
    padding: 8px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.chatbot button:hover {
    background-color: #45a049;
}

.loading {
    font-style: italic;
    color: #888;
}

.chatbot input:disabled,
.chatbot button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}