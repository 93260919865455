/* ExpenseTracker.css */

/* General styles for the expense tracker */
.expense-tracker-expensetracker {
    /* max-width: 800px; /* Limit the width of the tracker */
    margin: 0 auto; /* Center the tracker */
    /* padding: 20px; Add padding around the content */
    background-color: #ffffff; /* Light background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Table styles */
.table-expensetracker {
    width: 100%;
    border-collapse: collapse; /* Remove space between borders */
    /* margin-top: 20px; /* Space above the table */
}

.th-expensetracker, .td-expensetracker {
    border: 1px solid #ddd; /* Light border */
    /* padding: 10px; */ /* Padding inside cells */
    text-align: left; /* Align text to the left */
}

/* Input fields in the table */
.input-expensetracker {
    width: 100%; /* Full width */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Rounded corners */
    padding: 5px; /* Padding inside input */
    box-sizing: border-box; /* Include padding in width */
}

/* Change background color on hover */
.tr-expensetracker:hover {
    background-color: #4CAF50; /* Light gray on hover */
}

/* Button styles */
.button-container-expensetracker {
    display: flex;
    gap: 15px;
    margin-top: 20px;
    justify-content: center;
}

.button-add-expense-expensetracker,
.button-save-changes-expensetracker,
.button-analyze-expenses-expensetracker {
    min-width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.button-add-expense-expensetracker,
.button-save-changes-expensetracker {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    min-width: 120px;
}

.button-add-expense-expensetracker {
    background-color: #007bff;
    color: white;
}

.button-save-changes-expensetracker {
    background-color: #007bff;
    color: white;
}

.button-add-expense-expensetracker:hover {
    background-color: #4CAF50;
}

.button-save-changes-expensetracker:hover {
    background-color: #007bff;
}

.button-analyze-expenses-expensetracker {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button-analyze-expenses-expensetracker:hover {
    background-color: #4CAF50;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.button-analyze-expenses-expensetracker:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive styles */
@media (max-width: 600px) {
    .expense-tracker-expensetracker {
        padding: 10px; /* Reduce padding on smaller screens */
    }

    .input-expensetracker {
        font-size: 14px; /* Smaller font size for inputs */
    }

    .button-add-expense-expensetracker, .button-save-changes-expensetracker {
        width: 100%; /* Full width buttons on small screens */
        margin: 5px 0; /* Reduce margin */
    }
}

.delete-button-expensetracker {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 20px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    opacity: 0.7;
}

.delete-button-expensetracker:hover {
    opacity: 1;
    color: #4CAF50;
    transform: scale(1.2);
}

.delete-button-expensetracker:focus {
    outline: none;
}

/* Remove any default button styles */
.delete-button-expensetracker::-moz-focus-inner {
    border: 0;
}

.insights-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.button-insights-expensetracker {
    background-color: #007bff;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button-insights-expensetracker:hover {
    background-color: #4CAF50;
    transform: translateY(-2px);
}

.insights-container {
    margin-top: 20px;
    padding: 20px;
    background-color: #f8fafc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.insights-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.insight-item {
    padding: 16px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

.insight-item.exceeded-budget {
    background-color: #fee2e2;
    color: #dc2626;
    border-left: 4px solid #dc2626;
}

.insight-item.within-budget {
    background-color: #fef3c7;
    color: #d97706;
    border-left: 4px solid #d97706;
}

.insight-item.excess-budget {
    background-color: #dcfce7;
    color: #16a34a;
    border-left: 4px solid #16a34a;
}

.expense-analysis-table td.normal-budget {
    color: #10b981; /* Green */
    font-weight: 500;
}

.expense-analysis-table td.warning-budget {
    color: #f59e0b; /* Amber */
    font-weight: 500;
}

.expense-analysis-table td.exceeded-budget {
    color: #ef4444; /* Red */
    font-weight: 500;
}

.expense-analysis-table td {
    text-align: right;
}

.expense-analysis-table td:first-child {
    text-align: left;
}

.overshot-text {
    font-size: 12px;
    margin-left: 8px;
    font-style: italic;
    color: #ef4444; /* Red */
}

.action-section {
    margin-top: 30px;
    padding: 24px;
    background-color: #f8fafc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.action-section h3 {
    color: #1e293b;
    font-size: 1.5rem;
    margin-bottom: 16px;
}

.action-section p {
    color: #475569;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 24px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.button-take-action {
    background-color: #007bff;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.2);
}

.button-take-action:hover {
    background-color: #4CAF50;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(59, 130, 246, 0.3);
}

.button-take-action:active {
    transform: translateY(0);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.optimization-container {
    margin-top: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.optimization-tabs {
    display: flex;
    border-bottom: 1px solid #e2e8f0;
}



.tab-button {
    flex: 1;
    padding: 16px;
    background: #007bff;
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: #64748b;
    cursor: pointer;
    transition: all 0.3s ease;
}

.tab-button.active {
    color: #3b82f6;
    border-bottom: 2px solid #3b82f6;
    background-color: #f8fafc;
}

.tab-button:hover {
    background-color: #4CAF50;
}

.tab-content {
    padding: 24px;
}

.reduction-options {
    display: flex;
    gap: 12px;
    margin-top: 16px;
    flex-wrap: wrap;
}

.reduction-button {
    padding: 10px 20px;
    background-color: #e2e8f0;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #1e293b;
    cursor: pointer;
    transition: all 0.3s ease;
}

.reduction-button:hover {
    background-color: #4CAF50;
    transform: translateY(-1px);
}

.amount-input-container {
    display: flex;
    gap: 12px;
    margin-top: 16px;
}

.amount-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 14px;
}

.apply-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.apply-button:hover {
    background-color: #4CAF50;
    transform: translateY(-1px);
}

h4 {
    color: #1e293b;
    font-size: 16px;
    margin-bottom: 16px;
}

.apply-button:disabled {
    background-color: #cbd5e1;
    cursor: not-allowed;
    transform: none;
}

.amount-input::-webkit-inner-spin-button,
.amount-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.amount-input {
    -moz-appearance: textfield;
}

.adjustment-button-container {
    margin-top: 20px;
    text-align: center;
}

.button-add-adjustments {
    background-color: #007bff;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.button-add-adjustments:hover {
    background-color: #4CAF50;
}

.button-add-adjustments:active {
    background-color: #3d8b40;
}
.update-message {
    margin-top: 15px;
    padding: 10px;
    color: #4CAF50;
    font-weight: bold;
    text-align: center;
}
.ai-insights-section {
    margin-top: 20px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.ai-insights-section h3 {
    color: #2c3e50;
    margin-bottom: 15px;
}

.ai-insights-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.ai-insight-item {
    padding: 12px;
    background-color: #fff;
    border-left: 4px solid #3498db;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}
.button-apply-optimizations {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.button-apply-optimizations:hover {
    background-color: #4CAF50;
}

/* Add these styles for the description section */
.expense-description {
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 32px;
  border: 1px solid #007bff;
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.1);
}

.expense-description-header {
  text-align: center;
  margin-bottom: 20px;
}

.expense-description-header h2 {
  color: #1565c0;
  font-size: 1.8rem;
  margin-bottom: 8px;
}

.expense-description-subtitle {
  color: #1976d2;
  font-size: 1.1rem;
  margin: 0;
}

.expense-description-features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
}

.expense-feature-item {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.1);
  transition: transform 0.2s ease;
}

.expense-feature-item:hover {
  transform: translateY(-2px);
}

.expense-feature-icon {
  font-size: 1.2rem;
}

.expense-feature-item span:not(.expense-feature-icon) {
  color: #1976d2;
  font-weight: 500;
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .expense-description-features {
    flex-direction: column;
    align-items: center;
  }
  
  .expense-feature-item {
    width: 100%;
    justify-content: center;
  }
}
