.priority-container {
  max-width: 600px;
  margin: 20px auto 0;
  padding: 30px 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.priority-title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 0;
  visibility: visible;
  position: relative;
  z-index: 1;
}

.priorities-list {
  list-style: none;
  padding: 10px 30px;
  margin: 0 -30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: visible;
  height: auto !important;
}

.priority-item {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    font-family: 'Arial', sans-serif; /* A clean, readable font */
    font-size: 24px; /* Increased font size */
    font-weight: 600; /* Bold, but not too heavy */
    color: #333; /* Dark gray for better contrast */
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5); /* Subtle text shadow for depth */
    position: relative;
    opacity: 1 !important;
    height: auto !important;
    min-height: 40px;
    cursor: grab;
    user-select: none;
  }
  
  .priority-item:nth-child(7n+1) { background-color: #FF9AA2; }
  .priority-item:nth-child(7n+2) { background-color: #FFB7B2; }
  .priority-item:nth-child(7n+3) { background-color: #FFDAC1; }
  .priority-item:nth-child(7n+4) { background-color: #E2F0CB; }
  .priority-item:nth-child(7n+5) { background-color: #B5EAD7; }
  .priority-item:nth-child(7n+6) { background-color: #C7CEEA; }
  .priority-item:nth-child(7n+7) { background-color: #E0BBE4; }
  
  .priority-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .priority-rank {
    font-weight: bold;
    margin-right: 15px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    color: #333;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .priority-content {
    flex-grow: 1;
    font-size: 16px;
    color: #333;
    letter-spacing: 0.5px; /* Slightly increased letter spacing for readability */
  }
  
  .priority-buttons {
    display: flex;
    margin-left: 10px;
  }
  
  .priority-buttons button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    opacity: 0.7;
    transition: all 0.3s ease;
    color: #2196F3;
  }
  
  .priority-buttons button:hover:not(:disabled) {
    opacity: 1;
    color: #4CAF50;
  }
  
  .priority-buttons button:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  
  .priority-buttons img {
    width: 20px;
    height: 20px;
  }

.submit-button {
  display: block;
  width: 50%;
  padding: 12px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: auto;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.2);
}

.submit-button:hover {
  background-color: #4CAF50;
  box-shadow: 0 4px 8px rgba(76, 175, 80, 0.2);
}

@media (max-width: 480px) {
  .priority-container {
    padding: 20px 15px;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .priority-item {
    flex-wrap: wrap;
  }

  .priority-content {
    order: 1;
    width: calc(100% - 34px);
    margin-bottom: 10px;
  }

  .priority-buttons {
    order: 2;
    margin-left: auto;
  }

  .submit-button {
    width: 100%;
    font-size: 16px;
    padding: 10px;
  }
}

.content-container {
  position: relative;
  z-index: 1;
}

.priority-text {
  flex: 1;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
}

.priority-content {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.priority-description {
  text-align: left;
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}

.priority-content-container {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
  margin: 20px auto;
  min-height: 800px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  height: auto !important;
}

.priority-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: visible;
}

.priority-submit-button {
  align-self: center;
  padding: 10px 20px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.2);
}

.priority-submit-button:hover {
  background-color: #4CAF50;
  box-shadow: 0 4px 8px rgba(76, 175, 80, 0.2);
}

.priority-item:last-child {
  margin-bottom: 50px;
}

.submit-button:focus,
.priority-form-container .submit-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.3);
}

.priority-content-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
  margin: 20px auto;
  min-height: auto;
}

.priority-header {
  text-align: center;
  margin-bottom: 30px;
}

.priority-header h2 {
  color: #1565C0;
  font-size: 28px;
  margin-bottom: 16px;
}

.main-description {
  color: #424242;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 24px;
}

.priority-instruction-box {
  background-color: #E3F2FD;
  border-radius: 12px;
  padding: 24px 30px;
  margin-bottom: 30px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.1);
  border: 1px solid #BBDEFB;
}

.priority-instruction-title {
  color: #1565C0;
  font-size: 20px;
  margin-bottom: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.priority-instruction-list {
  margin: 0;
  padding-left: 24px;
  counter-reset: instruction-counter;
}

.priority-instruction-item {
  color: #2c3e50;
  margin-bottom: 12px;
  line-height: 1.6;
  font-size: 15px;
  position: relative;
  padding-left: 8px;
  counter-increment: instruction-counter;
}

.priority-highlight {
  color: #1976D2;
  font-weight: 500;
}

@media (max-width: 600px) {
  .priority-instruction-box {
    padding: 20px;
  }

  .priority-instruction-title {
    font-size: 18px;
  }

  .priority-instruction-item {
    font-size: 14px;
    line-height: 1.5;
  }
}

.priority-item.dragging {
  opacity: 0.9;
  background-color: #E3F2FD !important;
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(33, 150, 243, 0.2);
}

.priority-item:active {
  cursor: grabbing;
}

.priority-item::before {
  content: '⋮⋮';
  position: absolute;
  left: -30px;
  color: #90A4AE;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.priority-item:hover::before {
  opacity: 0.7;
}

.priority-instruction-title::before {
  content: '📝';  /* Cute notepad emoji */
  font-size: 24px;
  margin-right: 8px;
  display: inline-block;
  animation: bounce 1s ease infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}
