.header {
    background-color: #2196F3; /* Changed to Material Blue */
    color: #fff;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(33, 150, 243, 0.2); /* Adjusted shadow color */
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.app-name h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 600; /* Slightly reduced weight */
    color: #fff;
}

.menu-container {
    position: relative;
    display: flex;
    align-items: center;
}

.menu-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease; /* Added transition */
}

.menu-button:hover {
    opacity: 0.9; /* Added hover effect */
}

.menu-icon {
    display: inline-block;
    margin-right: 15px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff; /* Changed to white */
    box-shadow: 0 4px 8px rgba(33, 150, 243, 0.15); /* Adjusted shadow */
    border-radius: 8px; /* Increased radius */
    padding: 8px;
    display: flex;
    flex-direction: column;
    z-index: 2000;
    margin-top: 5px; /* Added small gap */
    border: 1px solid rgba(33, 150, 243, 0.1); /* Added subtle border */
}

.dropdown-menu .nav-link {
    color: #1976D2; /* Darker blue for text */
    text-decoration: none;
    padding: 10px 25px;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 4px; /* Added radius to links */
    font-weight: 500; /* Medium weight */
}

.dropdown-menu .nav-link:hover {
    background-color: #E3F2FD; /* Light blue background on hover */
    color: #1565C0; /* Slightly darker blue on hover */
}

.header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.profile-icon {
    color: white;
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-icon:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
  
  