.profile-container {
  padding: 2rem;
  max-width: 800px;
  margin: 80px auto 0;
  min-height: calc(100vh - 80px);
}

.profile-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.profile-header {
  text-align: center;
  margin-bottom: 2rem;
}

.profile-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 1rem;
  border: 4px solid #2196F3;
  padding: 4px;
}

.profile-header h1 {
  color: #333;
  font-size: 1.8rem;
  margin: 0.5rem 0;
}

.profile-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.detail-item {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
}

.detail-item label {
  display: block;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.detail-item p {
  color: #333;
  font-size: 1.1rem;
  margin: 0;
  font-weight: 500;
}

.profile-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.edit-button,
.change-password-button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.edit-button {
  background-color: #2196F3;
  color: white;
}

.change-password-button {
  background-color: #e0e0e0;
  color: #333;
}

.edit-button:hover {
  background-color: #1976D2;
}

.change-password-button:hover {
  background-color: #bdbdbd;
}

.loading,
.error {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
}

.error {
  color: #dc3545;
}

@media (max-width: 600px) {
  .profile-container {
    padding: 1rem;
  }
  
  .profile-card {
    padding: 1rem;
  }
  
  .profile-details {
    grid-template-columns: 1fr;
  }
  
  .profile-actions {
    flex-direction: column;
  }
  
  .edit-button,
  .change-password-button {
    width: 100%;
  }
} 