.progress-bar {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 30px;
  padding: 10px;
  background-color: #eaeaea;
  border-radius: 10px;
}

.progress-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #888;
  position: relative;
  flex: 1;
}

.progress-step::after {
  content: '';
  position: absolute;
  top: 15px;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #eaeaea;
  z-index: 1;
}

.progress-step:last-child::after {
  display: none;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
  position: relative;
  z-index: 2;
}

.step-name {
  font-size: 12px;
  text-align: center;
}

.progress-step.active .step-number {
  background-color: #4CAF50;
  color: white;
}

.progress-step.active::after {
  background-color: #4CAF50;
}

.progress-step.current .step-number {
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.3);
}

.progress-step.active .step-name {
  color: #4CAF50;
}

.progress-step.current .step-name {
  font-weight: bold;
}
