/* Budget Summary Table Styles */
.budget-summary-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: Arial, sans-serif;
    font-size: 1.1em;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.budget-summary-table th,
.budget-summary-table td {
    padding: 16px;
    text-align: left;
    border: 1px solid #ddd;
}

.budget-summary-table th {
    background-color: #007bff;
    font-weight: 600;
    color: #ffffff;
}

.budget-summary-table .section-header td {
    background-color: #8ca8b2;
    color: #ffffff;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    padding: 10px 0;
}

.budget-summary-table .total-row td {
    background-color: #f0f4c3;
    font-weight: bold;
    color: #33691e;
}

.budget-summary-table .net-income-negative td {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    border-top: 2px solid #ddd;
    background-color: #b05a62; /* Light red */
    color: #000000; /* Black text */
}

/* Positive or Zero Net Income */
.budget-summary-table .net-income-positive td{
    background-color: #7ac08b; /* Light green */
    color: #000000; /* Black text */
}

/* Negative Net Income */
.budget-summary-table .net-income-negative td{
    background-color: #740d16; /* Light red */
    color: #000000; /* Black text */
}

/* Row hover effect for better readability */
.budget-summary-table tr:hover {
    background-color: #4ab453;
    transition: background-color 0.3s ease;
}
