.pyramid-content-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pyramid-title {
  font-size: 28px;
  color: #2c3e50;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
}

/* Pyramid Description Styles */
.pyramid-description {
  max-width: 800px;
  margin: 0 auto 40px;
  padding: 24px 30px;
  background-color: #E3F2FD;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.1);
  border: 1px solid #BBDEFB;
  text-align: left;
}

.pyramid-description-title {
  color: #1565C0;
  font-size: 20px;
  margin-bottom: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  text-align: center;
}

.pyramid-description-title::before {
  content: "📝";
  font-size: 24px;
  margin-right: 8px;
  position: relative;
  top: -1px;
}

.pyramid-description-text {
  color: #2c3e50;
  line-height: 1.6;
  margin-bottom: 20px;
  font-size: 15px;
  text-align: center;
}

/* Key Points Styles */
.pyramid-key-points {
  margin: 0;
  padding-left: 24px;
  counter-reset: point-counter;
}

.pyramid-key-point {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 12px;
  margin-bottom: 12px;
  color: #2c3e50;
  line-height: 1.6;
  font-size: 15px;
  position: relative;
  transition: transform 0.3s ease;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.pyramid-key-point:hover {
  color: #1565C0;
  transform: translateX(4px);
  background-color: rgba(255, 255, 255, 0.9);
}

.pyramid-key-point:last-child {
  margin-bottom: 0;
}

.pyramid-point-number {
  background-color: #1565C0;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(21, 101, 192, 0.2);
}

.pyramid-key-point-text {
  margin: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* Pyramid Level Styles */
.pyramid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}

.pyramid-level {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  margin-bottom: 2px;
  color: white;
  font-weight: bold;
  text-align: center;
  transition: transform 0.3s ease;
}

.pyramid-level:hover {
  transform: scale(1.02);
}

/* Button Styles */
.pyramid-button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
}

.pyramid-submit-button,
.pyramid-secondary-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pyramid-submit-button {
  background-color: #4CAF50;
  color: white;
}

.pyramid-secondary-button {
  background-color: #f5f5f5;
  color: #333;
}

.pyramid-submit-button:hover {
  background-color: #45a049;
}

.pyramid-secondary-button:hover {
  background-color: #e0e0e0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .pyramid-description {
    padding: 20px;
    margin-bottom: 30px;
  }

  .pyramid-description-title {
    font-size: 18px;
  }

  .pyramid-key-point {
    font-size: 14px;
    line-height: 1.5;
  }

  .pyramid-button-container {
    width: 100%;
  }

  .pyramid-highlight {
    border-bottom-width: 1px;
  }

  .pyramid-description-text {
    font-size: 14px;
  }
}

/* Update the highlight styles */
.pyramid-highlight {
  color: #1565C0;
  font-weight: 600;
  padding: 0 2px;
  transition: all 0.3s ease;
}

.pyramid-highlight:hover {
  background-color: #BBDEFB;
  border-radius: 3px;
}
