/* Container Layout */
.aggregator-container {
  display: flex;
  width: 100%;
  max-width: 1540px;
  height: calc(100vh - 100px);
  margin: 0 auto;
  padding: 50px 20px 20px;
  font-family: Arial, sans-serif;
  justify-content: space-between;
  overflow: hidden;
  box-sizing: border-box;
}

.aggregator-main-content {
  flex: 0 0 70%;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;
}

/* Update Budget Summary Container */
.budget-summary-container {
  flex: 0 0 25%;
  position: sticky;
  top: 20px;
  max-height: calc(100vh - 140px); /* Account for padding */
  overflow-y: auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Add scrollbar styling for budget summary */
.budget-summary-container::-webkit-scrollbar {
  width: 8px;
}

.budget-summary-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.budget-summary-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.budget-summary-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Keep existing scrollbar styling for main content */
.aggregator-main-content::-webkit-scrollbar {
  width: 8px;
}

.aggregator-main-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.aggregator-main-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.aggregator-main-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Progress Section */
.progress-section {
  text-align: center;
  margin-bottom: 30px;
}

.progress-bar {
  width: 100%;
  height: 40px;
  background-color: #eaeaea;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: visible;
}

.progress {
  height: 100%;
  background-color: #4CAF50;
  border-radius: 5px;
  transition: width 0.3s ease;
}

/* Navigation */
.goal-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.goal-nav-button {
  padding: 10px 15px;
  margin: 0 5px 5px 0;
  background-color: #d3d3d3;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.goal-nav-button.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

/* Navigation Buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigation-buttons button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.navigation-buttons button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

/* Copy existing content-container styles with new name */
.aggregator-content-container {
  max-width: 1500px;
  /* margin: 0 auto; */
  /* padding: 20px; */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Copy and rename page container styles */
.aggregator-page-container {
  display: flex;
  max-width: 1500px;
  flex-direction: column;
  min-height: 100vh;  
  background-color: #ffffff;
}

.finish-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  transition: background-color 0.3s;
}

.finish-button:hover {
  background-color: #45a049;
}

.finish-button:active {
  background-color: #3d8b40;
}

/* Sticky Note Styles */
.sticky-note {
  margin: 20px 0;
  padding: 15px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.sticky-note.balanced {
  background-color: #e8f5e9;
  border: 1px solid #4caf50;
  color: #2e7d32;
}

.sticky-note.unbalanced {
  background-color: #ffebee;
  border: 1px solid #ef5350;
  color: #c62828;
}

.sticky-note-text {
  margin: 0;
  line-height: 1.4;
}

.tabs-container {
  display: flex;
  gap: 10px;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
}

.tab {
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  color: #666;
  transition: all 0.3s ease;
}

.tab:hover {
  background: #f5f5f5;
}

.tab.active {
  background: #1976D2;
  color: white;
}

.tab-content {
  padding: 20px;
}