.Login {
    text-align: center;
    margin-top: 200px;
  }
  
  form {
    display: inline-block;
    text-align: left;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .login-button {
    background-color: #61dafb;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    color: #282c34;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
  
  .login-button:hover {
    background-color: #21a1f1;
  }
  .login-page {
    background-color: rgba(255, 255, 255, 0.5); /* Half white background */
    padding: 20px;
    text-align: center;
  }
  
  form input {
    display: block;
    margin: 10px auto;
    padding: 10px;
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #2196F3, #64B5F6);
    padding: 20px;
  }
  
  .login-box {
    background: white;
    border-radius: 16px;
    padding: 40px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  }
  
  .login-header {
    text-align: center;
    margin-bottom: 32px;
  }
  
  .login-header h2 {
    color: #1565C0;
    font-size: 24px;
    margin-bottom: 8px;
  }
  
  .login-header p {
    color: #666;
    font-size: 14px;
  }
  
  .form-group {
    margin-bottom: 24px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: 500;
    font-size: 14px;
  }
  
  .input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .input-icon {
    position: absolute;
    left: 12px;
    color: #666;
  }
  
  .input-container input {
    width: 100%;
    padding: 12px 12px 12px 40px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.3s ease;
  }
  
  .input-container input:focus {
    border-color: #2196F3;
    outline: none;
    box-shadow: 0 0 0 3px rgba(33, 150, 243, 0.1);
  }
  
  .toggle-password {
    position: absolute;
    right: 12px;
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 0;
  }
  
  .form-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .remember-me {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #666;
  }
  
  .remember-me input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin: 0;
  }
  
  .forgot-password {
    color: #2196F3;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
  }
  
  .forgot-password:hover {
    color: #1976D2;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background: #2196F3;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background: #1976D2;
  }
  
  .signup-prompt {
    text-align: center;
    margin-top: 24px;
    font-size: 14px;
    color: #666;
  }
  
  .signup-link {
    color: #2196F3;
    text-decoration: none;
    font-weight: 500;
    margin-left: 4px;
    transition: color 0.3s ease;
  }
  
  .signup-link:hover {
    color: #1976D2;
  }
  
  .error-message {
    background: #ffebee;
    color: #c62828;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 24px;
    font-size: 14px;
    text-align: center;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .login-box {
      padding: 24px;
    }
  
    .login-header h2 {
      font-size: 20px;
    }
  
    .input-container input {
      padding: 10px 10px 10px 36px;
    }
  }