.retirement-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
}

.retirement-content-container {
  flex: 1;
  /* padding: 20px; */
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.retirement-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.retirement-table {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.retirement-input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  position: relative;
}

.retirement-input-row label {
  flex: 1;
  font-weight: 500;
  color: #333;
  text-align: left;
  padding-right: 20px;
}

.retirement-input-row input {
  width: 200px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  text-align: right;
}

.retirement-input-row input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
}

.retirement-header-section {
  margin-bottom: 30px;
  padding: 20px 0;
}

.retirement-title {
  font-size: 28px;
  color: #333;
  margin-bottom: 15px;
  font-weight: 600;
}

.retirement-description {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

.retirement-expenses {
  font-size: 16px;
  color: #333;
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border-left: 4px solid #007bff;
}

.retirement-expenses-breakdown {
  color: #666;
  font-size: 14px;
  margin-left: 10px;
}

.retirement-expenses.annual {
  margin-top: 10px;
  color: #333;
  font-weight: 500;
  background-color: #f0f8ff;  /* Light blue background to differentiate */
  border-left: 4px solid #0056b3;  /* Darker blue border */
}

.lifestyle-section {
  margin: 30px 0;
}

.lifestyle-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.lifestyle-options {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.lifestyle-tile {
  flex: 1;
  background: white;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;  /* Adjust as needed */
}

.lifestyle-tile.selected {
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
}

.lifestyle-tile input[type="radio"] {
  position: absolute;
  top: 15px;
  left: 15px;
}

.lifestyle-icon {
  font-size: 32px;
  color: #007bff;
  margin-bottom: 15px;
}

.lifestyle-tile h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.lifestyle-amount {
  font-size: 24px;
  color: #007bff;
  font-weight: 600;
  margin: 10px 0 5px;
}

.lifestyle-calculation {
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;
}

.lifestyle-description {
  font-size: 16px;  /* Increased from 14px */
  color: #666;
  line-height: 1.4;
  margin-top: auto;  /* Push to bottom of flex container */
}

.custom-expense-section {
  margin-top: 20px;
  text-align: center;
  padding: 15px;
  border-top: 1px solid #eee;
}

.custom-expense-text {
  font-size: 16px;
  color: #333;
}

.custom-expense-input {
  margin-left: 10px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  font-size: 16px;
}

.custom-expense-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

.retirement-calculations {
  margin-top: 30px;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.calculation-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.calculation-item:last-child {
  border-bottom: none;
}

.calculation-item label {
  font-weight: 500;
  color: #333;
}

.calculation-item span {
  font-size: 18px;
  color: #007bff;
  font-weight: 600;
}

.retirement-context {
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  text-align: center;
}

.reassuring-text {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
  margin-bottom: 20px;
}

.highlight {
  color: #007bff;
  font-weight: 500;
}

.plan-savings-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.plan-savings-btn:hover {
  background-color: #0056b3;
}

.savings-plan-section {
  margin: 30px 0;
}

.savings-plan-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 25px;
}

.savings-step {
  background: #f8f9fa;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.step-title {
  font-size: 20px;
  color: black;
  margin-bottom: 15px;
}

.step-question {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.savings-options {
  display: flex;
  gap: 2rem;
  margin: 1.5rem 0;
}

.savings-option {
  flex: 1;
  padding: 2rem;
  border: 2px solid #ddd;
  border-radius: 12px;
  background: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.savings-option:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.savings-option.selected {
  border-color: #4CAF50;
  background: #f8fff8;
}

.savings-option h4 {
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.savings-amount-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.amount-label {
  font-size: 0.9rem;
  color: #666;
}

.amount-value {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2c3e50;
}

.increment-note {
  font-size: 0.9rem;
  color: #666;
  font-style: italic;
}

.save-details-btn {
  margin-top: 2rem;
  padding: 1rem 2rem;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.save-details-btn:hover {
  background: #45a049;
}

.savings-note {
  color: #666;
  margin-top: 1.5rem;
  font-size: 0.9rem;
  text-align: center;
}

.current-savings-input {
  margin-top: 20px;
}

.current-savings-input label {
  display: block;
  margin-bottom: 10px;
  color: #555;
}

.input-with-currency {
  display: flex;
  align-items: center;
  gap: 10px;
}

.currency-symbol {
  padding: 10px;
  background: #f0f0f0;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 4px 0 0 4px;
  color: #666;
}

.current-savings-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
  font-size: 16px;
}

.no-savings-message {
  margin-top: 20px;
  padding: 15px;
  background: #e8f4ff;
  border-radius: 6px;
  color: #0056b3;
  font-size: 16px;
}

.confirm-savings-btn {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.confirm-savings-btn:hover {
  background-color: #0056b3;
}

/* Original Yes/No buttons styling */
.savings-question {
  margin: 20px 0;
}

.savings-options-btns {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.savings-option-btn {
  padding: 8px 24px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

.savings-option-btn:hover {
  background-color: #f5f5f5;
}

.savings-option-btn.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

/* Add these styles for the description section */
.retirement-description {
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 32px;
  border: 1px solid #2196f3;
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.1);
}

.retirement-description-header {
  text-align: center;
  margin-bottom: 20px;
}

.retirement-description-header h2 {
  color: #1565c0;
  font-size: 1.8rem;
  margin-bottom: 8px;
}

.retirement-description-subtitle {
  color: #1976d2;
  font-size: 1.1rem;
  margin: 0;
}

.retirement-description-features {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 12px;
  margin-top: 20px;
  padding: 0 12px;
}

.retirement-feature-item {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: white;
  padding: 10px 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.1);
  transition: transform 0.2s ease;
}

.retirement-feature-item:hover {
  transform: translateY(-2px);
}

.retirement-feature-icon {
  font-size: 1.2rem;
}

.retirement-feature-item span:not(.retirement-feature-icon) {
  color: #1976d2;
  font-weight: 500;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .retirement-description-features {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .retirement-feature-item {
    flex: 1 1 calc(50% - 12px);
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .retirement-feature-item {
    flex: 1 1 100%;
  }
}

.retirement-calculator-container {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

/* Header styling for h3 in calculator section */
.retirement-calculator-section h3 {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #e8f4ff;
    border-radius: 4px;
    text-align: left;
}

button.calculator-tab {
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
    min-width: 180px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #007bff;
    color: white;
    margin: 5px;
}

button.calculator-tab:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

button.calculator-tab.active {
    background-color: #0056b3;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
}

button.calculator-tab:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.retirement-plan-details {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.retirement-plan-details h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.retirement-plan-details p {
  font-size: 1em;
  margin: 5px 0;
  color: #555;
  line-height: 1.5;
} 
