/* General styles */
/* .em-page-container {
    align-content: center;
    max-width: 1500px;
    padding: 20px;
} */
.emergency-fund-page {
    width: 100%;
    /* max-width: 928px; */
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.em-content-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    overflow-x: hidden;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

/* Form styles */
.emergency-fund-form {
    margin-bottom: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th, td {
    padding: 10px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
    border-bottom: 2px solid #ddd;
}

td {
    border-bottom: 1px solid #ddd;
}

/* Input styles */
.input-select, .input-number {
    width: 100%;
    padding: 8px;
    margin: 4px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-select:focus, .input-number:focus {
    border-color: #36A2EB;
    outline: none;
}

.add-category-button, .calculate-button, .get-started-button {
    background-color: #007bff;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
    margin-left: 20px;
    font-size: 14px;
    min-width: 100px;
}

.add-category-button {
    display: block;
    width: auto;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 14px;
}

.calculate-button {
    display: block;
    width: auto;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 14px;
}

.add-category-button:hover, .calculate-button:hover {
    background-color: #1976D2;
}

/* Error message styles */
.error-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

/* Result and recommendation styles */
/*h3 {
    margin-top: 10px;
    font-size: 20px;
    color: #333;
}*/

p {
    font-size: 18px;
    color: #555;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
    color: #555;
}

.salary-input-section {
    margin-bottom: 20px;
}

.salary-label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
}

.salary-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.existing-fund-section {
    margin-bottom: 20px;
}

.existing-fund-label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
}

.existing-fund-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Emergency fund options */
.options-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 15px;
  max-width: 900px;
  margin: 0 auto;
}

.fund-card {
  position: relative;
  background: white;
  border-radius: 12px;
  padding: 20px;
  border: 2px solid #e0e0e0 !important;
  transition: all 0.3s ease;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

.fund-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(33, 150, 243, 0.15);
  border-color: #90caf9 !important;
}

.fund-card.selected {
  border-color: #007bff !important;
  background: linear-gradient(145deg, #f5f9ff, #e3f2fd) !important;
  box-shadow: 0 8px 20px rgba(33, 150, 243, 0.2);
}

.fund-card input[type="radio"] {
  position: absolute;
  top: 12px;
  right: 12px;
  margin: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.option-content {
  width: 100%;
  margin-top: 8px;
}

.option-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1565C0;
  margin-bottom: 8px;
}

.option-value {
  font-size: 1.3rem;
  font-weight: 700;
  color: #007bff;
  margin: 12px 0;
}

.option-description {
  font-size: 0.85rem;
  color: #666;
  line-height: 1.3;
  margin-top: 6px;
}

.fund-card.selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #007bff, #64B5F6);
  border-radius: 4px 4px 0 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .options-section {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 10px;
  }

  .fund-card {
    padding: 20px;
  }

  .option-title {
    font-size: 1.1rem;
  }

  .option-value {
    font-size: 1.3rem;
  }
}

.section-description {
    margin-bottom: 20px;
    color: #666;
    font-size: 14px;
}

.fund-main-text {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
    color: #333;
}

.fund-calculation {
    font-size: 14px;
    color: #666;
}

/* Add these styles for the description section */
.emergency-description {
  background: linear-gradient(135deg, #e3f2fd, #bbdefb);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 32px;
  border: 1px solid #007bff;
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.1);
}

.emergency-description-header {
  text-align: center;
  margin-bottom: 20px;
}

.emergency-description-header h2 {
  color: #1565c0;
  font-size: 1.8rem;
  margin-bottom: 8px;
}

.emergency-description-subtitle {
  color: #1976d2;
  font-size: 1.1rem;
  margin: 0;
}

.emergency-description-features {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 12px;
  margin-top: 20px;
  padding: 0 12px;
}

.emergency-feature-item {
  flex: 1;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: white;
  padding: 10px 12px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.1);
  transition: transform 0.2s ease;
}

.emergency-feature-item:hover {
  transform: translateY(-2px);
}

.emergency-feature-icon {
  font-size: 1.2rem;
}

.emergency-feature-item span:not(.emergency-feature-icon) {
  color: #1976d2;
  font-weight: 500;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Update mobile responsiveness */
@media (max-width: 768px) {
  .emergency-description-features {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .emergency-feature-item {
    flex: 1 1 calc(50% - 12px);
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .emergency-feature-item {
    flex: 1 1 100%;
  }
}

/* Add these styles to create a visual separation between EmergencyFund and FundPlan */
.em-content-container {
  position: relative;
  padding-bottom: 30px;
}

/* Add a subtle divider when FundPlan is shown */
.em-content-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 1px;
  background: linear-gradient(90deg, transparent, #e0e0e0, transparent);
}

/* Adjust spacing for better visual flow */
.calculation-results {
  margin-bottom: 40px;
}

/* Style the transition between sections */
.fund-plan-page {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Emergency Fund Summary Table Styles */
.emergency-fund-summary {
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 32px;
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.1);
  border: 1px solid #e0e0e0;
}

.emergency-fund-summary h3 {
  color: #1565c0;
  font-size: 1.4rem;
  margin-bottom: 16px;
  text-align: center;
}

.summary-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
  table-layout: fixed;
}

.summary-table th {
  background-color: #f5f9ff;
  color: #1565c0;
  font-weight: 600;
  text-align: center;
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.summary-table td {
  padding: 12px 16px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 500;
  font-size: 1rem;
}

.summary-table tr:hover {
  background-color: #f8f9fa;
}

/* Responsive adjustments for the summary table */
@media (max-width: 768px) {
  .emergency-fund-summary {
    padding: 16px;
    overflow-x: auto;
  }

  .summary-table {
    min-width: 600px;
  }

  .summary-table th,
  .summary-table td {
    padding: 8px 12px;
    font-size: 0.85rem;
  }
}
